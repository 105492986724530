



export const START_FETCHING_ABOUT = 'START_FETCHING_ABOUT';
export const FETCHING_ABOUT_SUCCESS = 'FETCHING_ABOUT_SUCCESS';
export const FETCHING_ABOUT_FAILURE = 'FETCHING_ABOUT_FAILURE';

export const ABOUT_SPINNER_STARTS = 'ABOUT_SPINNER_STARTS'
export const ABOUT_SPINNER_STOPS = 'ABOUT_SPINNER_STOPS'
export const START_FETCHING_ABOUT_WITH_SPINNER = 'START_FETCHING_ABOUT_WITH_SPINNER'