export const START_FETCHING_FOOTER_ABOUT = "START_FETCHING_FOOTER_ABOUT";
export const FETCHING_FOOTER_ABOUT_SUCCESS = "FETCHING_FOOTER_ABOUT_SUCCESS";
export const FETCHING_FOOTER_ABOUT_FAILURE = "FETCHING_FOOTER_ABOUT_FAILURE";
export const FOOTER_ABOUT_SPINNER_STARTS = "FOOTER_ABOUT_SPINNER_STARTS";
export const FOOTER_ABOUT_SPINNER_STOPS = "FOOTER_ABOUT_SPINNER_STOPS";
export const START_FETCHING_FOOTER_ABOUT_WITH_SPINNER =
  "START_FETCHING_FOOTER_ABOUT_WITH_SPINNER";

export const START_FETCHING_FOOTER_LINKS = "START_FETCHING_FOOTER_LINKS";
export const FETCHING_FOOTER_LINKS_SUCCESS = "FETCHING_FOOTER_LINKS_SUCCESS";
export const FETCHING_FOOTER_LINKS_FAILURE = "FETCHING_FOOTER_LINKS_FAILURE";
export const FOOTER_LINKS_SPINNER_STARTS = "FOOTER_LINKS_SPINNER_STARTS";
export const FOOTER_LINKS_SPINNER_STOPS = "FOOTER_LINKS_SPINNER_STOPS";
export const START_FETCHING_FOOTER_LINKS_WITH_SPINNER =
  "START_FETCHING_FOOTER_LINKS_WITH_SPINNER";

export const START_FETCHING_FOOTER_CONTACT = "START_FETCHING_FOOTER_CONTACT";
export const FETCHING_FOOTER_CONTACT_SUCCESS =
  "FETCHING_FOOTER_CONTACT_SUCCESS";
export const FETCHING_FOOTER_CONTACT_FAILURE =
  "FETCHING_FOOTER_CONTACT_FAILURE";
export const FOOTER_CONTACT_SPINNER_STARTS = "FOOTER_CONTACT_SPINNER_STARTS";
export const FOOTER_CONTACT_SPINNER_STOPS = "FOOTER_CONTACT_SPINNER_STOPS";
export const START_FETCHING_FOOTER_CONTACT_WITH_SPINNER =
  "START_FETCHING_FOOTER_CONTACT_WITH_SPINNER";
